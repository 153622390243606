.track {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  animation: track 0.5s var(--transition) 1;
}

.track__content {
  z-index: 50;
  width: 100%;
  padding-bottom: calc(var(--padding) * 1.5);
}

.track__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
}

.track__controls__btn {
  cursor: pointer;
  position: relative;
  padding: 0 calc(var(--padding) / 1.5);
}

.track__controls__cross {
  position: absolute;
  width: 3px;
  height: 30px;
  border-radius: 3px;
  transform-origin: top right;
  background: var(--color-primary);
  transform: rotate(-45deg) translate(-0.5px, -0.5px);
}

.track__details {
  text-align: center;
  padding: var(--padding);
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.track__details h1 {
  margin: 0 auto;
  font-size: 28px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
}

.track__details h1.overflowed {
  width: var(--scroll-width);
  overflow: unset;
  text-overflow: unset;
  display: inline-block;
  animation-delay: 600ms;
  animation: floatText 20s infinite linear;
}
.track__details h1:hover {
  animation-play-state: paused;
}

@keyframes floatText {
  0% {
    transform: translateX(0);
  }
  50% {
    visibility: hidden;
    transform: translateX(calc(var(--scroll-width) * -1.1));
  }
  50.1% {
    transform: translateX(calc(var(--client-width) * 1.1));
  }
  50.2% {
    visibility: hidden;
  }
  50.3% {
    visibility: visible;
  }
  90% {
    transform: translateX(0);
  }
}

@keyframes track {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.track__slider__img {
  width: 85%;
  height: 85%;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--bg-color-accent);
}

.track__slider__img > img {
  width: 100%;
}

.track__visualizer {
  top: 40%;
  position: absolute;
}
