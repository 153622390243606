.home {
  width: 100%;
  overflow-y: auto;
  height: calc(100%);
  animation: home 0.5s var(--transition) 1;
}

.home__input {
  padding: var(--padding);
  padding-top: calc(var(--padding) / 4);
  padding-bottom: calc(var(--padding) / 4);
}

@keyframes home {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
