.header {
  width: 100%;
  display: flex;
  min-height: 88px;
  align-items: center;
  justify-content: space-between;
}
.header__menu__left {
  padding-left: calc(var(--padding) * 1);
}
.header__menu__right {
  padding-right: calc(var(--padding) * 1);
}

.header__spacer {
  flex-grow: 1;
}

.header__title {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 0px var(--padding);
}

.header__icon {
  width: 48px;
  height: 48px;
  display: flex;
  cursor: pointer;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}
.header__icon:hover {
  background: var(--bg-color-accent);
}

.header__icon img {
  width: 30px;
}
