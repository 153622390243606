@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&display=swap);
:root {
  --padding: 24px;

  --bg-color: #191b2d;
  --bg-color-accent: #35395f;
  --bg-color-accent-2: #20233a;
  --bg-color-secondary: #4a5085;

  --color-primary: rgba(255, 255, 255, 1);
  --color-secondary: rgba(255, 255, 255, 0.78);
  --color-disabled: rgba(255, 255, 255, 0.46);

  --transition: cubic-bezier(0.62, 0.44, 0, 1);
}

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-weight: 300;
  overscroll-behavior-y: none;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.empty {
  display: flex;
  padding: 0 12% 0 12%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - var(--padding) * 7);
  padding-bottom: calc(var(--padding) * 2);
}

.empty__img {
  margin-bottom: var(--padding);
}

.empty__message,
.empty__description {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.empty__message {
  font-weight: 600;
  margin-bottom: calc(var(--padding) / 2);
}

.empty__description {
  font-size: 13px;
}

.button__wrapper {
  z-index: 1;
  outline: none;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  padding: 8px 20px;
  position: relative;
  border-radius: 32px;
  text-decoration: none;
  box-sizing: border-box;
  font-family: sans-serif;
  text-transform: uppercase;
  min-width: var(--button-size);
  min-height: var(--button-size);
  background: linear-gradient(90deg, #833ab4, #fcb045, #fd1d1d, #833ab4);
  background-size: 400%;
}

.button__wrapper:hover,
.button__wrapper.button__wrapper--active {
  animation: animate 8s linear infinite;
  transition: 0.3s;
}

.button__content {
  --surround: calc(var(--button-size) / 8);
  display: flex;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  background: #191b2d;
  align-items: center;
  justify-content: center;
  top: calc(var(--surround) / 2);
  left: calc(var(--surround) / 2);
  width: calc(100% - var(--surround));
  height: calc(100% - var(--surround));
  font-size: calc(var(--button-size) / 2.5);
}

@keyframes animate {
  0% {
    background-position: 400%;
  }
  100% {
    background-position: 0%;
  }
}

.button__wrapper:before {
  content: '';
  opacity: 0;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  transition: 0.3s;
  border-radius: 40px;
  position: absolute;
  background: linear-gradient(90deg, #833ab4, #fcb045, #fd1d1d, #833ab4);
  background-size: 400%;
}

.button__wrapper:hover:before {
  opacity: 1;
  transition: 0.3s;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  animation: animate 8s linear infinite;
}

.slider {
  position: relative;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
}

.slider__range {
  opacity: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}

.slider__track {
  top: 50%;
  height: 50%;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  background: #fcb045; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #fcb045,
    #fd1d1d,
    #833ab4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 0 0 var(--size) var(--size);
}

.slider__dial {
  left: 0px;
  z-index: 40;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  will-change: transform;
  width: var(--dial-size);
  height: var(--dial-size);
  background-color: white;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  --dial-calc: calc(calc(var(--dial-size) / 2) * -0.5);
  transform: translate(
    var(--dial-calc),
    calc(calc(var(--size) / 2) + var(--dial-calc))
  );
}
.slider__dial:focus {
  outline: none;
}

.slider__info {
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border-radius: 50%;
  position: absolute;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--bg-color);
  font: 400 16px/1.1 'Roboto', sans-serif;
  top: calc(calc(var(--dial-size) / 2));
  left: calc(calc(var(--dial-size) / 2));
  width: calc(var(--size) - calc(calc(var(--dial-size))));
  height: calc(var(--size) - calc(calc(var(--dial-size))));
}

.slider__blocker {
  left: -50%;
  width: 200%;
  height: 200%;
  position: absolute;
  will-change: transform;
  transform-origin: top center;
  background-color: var(--bg-color-secondary);
}

.slider__end,
.slider__start {
  z-index: 0;
  position: absolute;
  background: #833ab4;
  border-radius: 50% 50% 0 0;
  width: calc(var(--dial-size) / 2);
  height: calc(var(--dial-size) / 2);
  top: calc(50% - var(--dial-size) / 4.1);
  -webkit-clip-path: polygon(0 0, 120% 0%, 120% 50%, 0 50%);
          clip-path: polygon(0 0, 120% 0%, 120% 50%, 0 50%);
}

.slider__start {
  background-color: var(--bg-color-secondary);
  right: 0;
}

.slider__currtime,
.slider__totaltime {
  font-size: 12px;
  z-index: 50;
  left: -12px;
  font-weight: bold;
  position: absolute;
  color: var(--color-secondary);
  top: calc(40% - var(--dial-size) / 4.1);
}

.slider__totaltime {
  right: -12px;
  left: unset;
}

.header {
  width: 100%;
  display: flex;
  min-height: 88px;
  align-items: center;
  justify-content: space-between;
}
.header__menu__left {
  padding-left: calc(var(--padding) * 1);
}
.header__menu__right {
  padding-right: calc(var(--padding) * 1);
}

.header__spacer {
  flex-grow: 1;
}

.header__title {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 0px var(--padding);
}

.header__icon {
  width: 48px;
  height: 48px;
  display: flex;
  cursor: pointer;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}
.header__icon:hover {
  background: var(--bg-color-accent);
}

.header__icon img {
  width: 30px;
}

.input__wrapper {
  --here-bg: var(--bg-color-accent);
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--here-bg);
  justify-content: space-between;
  transition: all 0.5s var(--transition);
  border-radius: calc(var(--padding) / 2);
  width: calc(100% - var(--padding) * 1.6);
  padding: calc(var(--padding) / 1.5) calc(var(--padding) * 0.8);
}

.input__wrapper--focus {
  background: var(--bg-color-secondary);
}
input {
  outline: none;
  border: none;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'Lexend';
  text-transform: uppercase;
  background: var(--here-bg);
  color: var(--color-primary);
  transition: all 0.5s var(--transition);
  width: calc(100% - var(--padding) * 2);
}

input:focus {
  --here-bg: var(--bg-color-secondary);
}

input::-webkit-input-placeholder {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'Lexend';
  text-transform: uppercase;
  color: var(--color-disabled);
}

input:-ms-input-placeholder {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'Lexend';
  text-transform: uppercase;
  color: var(--color-disabled);
}

input::placeholder {
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'Lexend';
  text-transform: uppercase;
  color: var(--color-disabled);
}

.input__icon {
  padding-right: 2px;
}

.home {
  width: 100%;
  overflow-y: auto;
  height: calc(100%);
  animation: home 0.5s var(--transition) 1;
}

.home__input {
  padding: var(--padding);
  padding-top: calc(var(--padding) / 4);
  padding-bottom: calc(var(--padding) / 4);
}

@keyframes home {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.menu {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  will-change: transform;
  transform: translate3d(-120%, 0, 0);
  background: var(--bg-color-secondary);
  transition: transform 0.6s var(--transition);
}

.menu.menu--show {
  transform: translate3d(0%, 0, 0);
}

.menu__content {
  overflow-y: auto;
  height: calc(100% - 88px - var(--padding));
}

.menu__item {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  align-items: center;
  padding: var(--padding);
  padding-right: calc(var(--padding) * 1.5);
}
.menu__item:hover {
  background-color: var(--bg-color-accent);
}

.menu__item__icon {
  height: 32px;
}

.menu__item__name {
  font-size: 18px;
  overflow: hidden;
  font-weight: 400;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 0px var(--padding) 0px 0px;
  width: calc(100% - 32px - var(--padding) * 2);
}

.menu__item--spacer {
  flex-grow: 1;
}

.track {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  animation: track 0.5s var(--transition) 1;
}

.track__content {
  z-index: 50;
  width: 100%;
  padding-bottom: calc(var(--padding) * 1.5);
}

.track__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
}

.track__controls__btn {
  cursor: pointer;
  position: relative;
  padding: 0 calc(var(--padding) / 1.5);
}

.track__controls__cross {
  position: absolute;
  width: 3px;
  height: 30px;
  border-radius: 3px;
  transform-origin: top right;
  background: var(--color-primary);
  transform: rotate(-45deg) translate(-0.5px, -0.5px);
}

.track__details {
  text-align: center;
  padding: var(--padding);
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.track__details h1 {
  margin: 0 auto;
  font-size: 28px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
}

.track__details h1.overflowed {
  width: var(--scroll-width);
  overflow: unset;
  text-overflow: unset;
  display: inline-block;
  animation-delay: 600ms;
  animation: floatText 20s infinite linear;
}
.track__details h1:hover {
  animation-play-state: paused;
}

@keyframes floatText {
  0% {
    transform: translateX(0);
  }
  50% {
    visibility: hidden;
    transform: translateX(calc(var(--scroll-width) * -1.1));
  }
  50.1% {
    transform: translateX(calc(var(--client-width) * 1.1));
  }
  50.2% {
    visibility: hidden;
  }
  50.3% {
    visibility: visible;
  }
  90% {
    transform: translateX(0);
  }
}

@keyframes track {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.track__slider__img {
  width: 85%;
  height: 85%;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--bg-color-accent);
}

.track__slider__img > img {
  width: 100%;
}

.track__visualizer {
  top: 40%;
  position: absolute;
}

.playlist {
  width: calc(100% - calc(var(--padding) * 2));
  --item-padding: calc(var(--padding) * 0.6);
  padding: var(--padding);
  padding-bottom: 0;
}

.playlist--grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.playlist--grid .playlist__item {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - var(--padding) * 1.8);
}

.playlist--grid .playlist__item .playlist__name {
  min-height: 40px;
  white-space: normal;
  text-overflow: unset;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: calc(100% - 52px);
  -webkit-box-orient: vertical;
  margin-bottom: var(--padding);
}

.playlist__duration {
  width: 36px;
  height: 100%;
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  padding-left: 12px;
}

.playlist--grid .playlist__item .playlist__duration {
  padding-top: 2px;
}

.playlist--grid .playlist__item .playlist__icon--right {
  margin-left: 0px;
  background: var(--bg-color);
}
.playlist--grid .playlist__item .playlist__icon {
  margin-right: 0px;
  background: var(--bg-color);
}

.playlist__icon:before {
  content: '';
  opacity: 0;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  transition: 0.3s;
  border-radius: 40px;
  position: absolute;
  background: linear-gradient(90deg, #fd1d1d, #833ab4, #833ab4, #fd1d1d);
  background-size: 400%;
}

.playlist--bottom-padded {
  padding-bottom: calc(var(--padding) * 3.2);
}

.playlist__item {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  background: var(--bg-color-accent-2);
  border-radius: calc(var(--padding) / 2);
  margin-bottom: calc(var(--padding) * 1.2);
  padding: var(--item-padding);
}

.playlist__item--selected {
  background: var(--bg-color-accent);
}

.playlist__icon {
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 24px;
  transition: all 0.3s ease;
  background: var(--bg-color);
  margin-right: var(--item-padding);
}
@keyframes animate {
  0% {
    background-position: 400%;
  }
  100% {
    background-position: 0%;
  }
}

.playlist__icon:hover,
.playlist__item .playlist__icon:hover:before {
  opacity: 1;
  transition: 0.3s;
  transition: all 0.3s ease;
  animation: animate 8s linear infinite;
}

.playlist__name {
  overflow: hidden;
  font-weight: 400;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: calc(100% - 172px);
}

.playlist__icon--right {
  margin-right: 0px;
  background: transparent;
  margin-left: var(--item-padding);
}

.nowplaying {
  bottom: 0;
  z-index: 3;
  display: flex;
  margin: 0px auto;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  transform: translateY(150%);
  transition: transform 0.5s var(--transition);
  --padding-here: calc(var(--padding) * 0.8);
}

.nowplaying.nowplaying--open {
  transform: translateY(0%);
  transition: transform 0.5s ease;
}

.nowplaying__container {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 650px;
  align-items: center;
  padding: var(--padding-here) 0px;
  justify-content: space-between;
  background: var(--bg-color-accent);
  padding-top: calc(var(--padding-here) - 2px);
  border-radius: var(--padding) var(--padding) 0 0;
  border-top: 1px solid var(--color-dim);
  border-left: 1px solid var(--color-dim);
  border-right: 1px solid var(--color-dim);
}

.nowplaying__title__wrapper {
  z-index: 1;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  width: calc(100% - 106px);
  padding: 0px var(--padding-here);
}

.nowplaying__title {
  overflow: hidden;
  font-size: 20px;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding-left: var(--padding-here);
}

.nowplaying__title__icon {
  width: 28px;
}

.nowplaying__button {
  padding-right: var(--padding-here);
}

.nowplaying__progress,
.nowplaying__progress__placeholder {
  bottom: 0;
  height: 4px;
  width: inherit;
  position: absolute;
  background: #fcb045; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #fcb045, #fd1d1d, #833ab4);
}

.nowplaying__progress {
  z-index: 4;
  right: 0;
  max-width: 650px;
  background: var(--bg-color);
}
.nowplaying__progress__placeholder {
  z-index: 3;
}

.nowplaying__icon {
  min-width: 32px;
}

.nowplaying__visualizer {
  position: absolute;
  z-index: 0;
  top: 10px;
}

.app__wrapper {
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  background-color: var(--bg-color);
}

.app__container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.app__content {
  z-index: 2;
  width: 100%;
  background: transparent;
}

@media (min-width: 700px) {
  .app__wrapper {
    background-color: var(--bg-color-secondary);
  }
  .app__container {
    width: 700px;
    overflow: hidden;
    height: calc(100% - 96px);
    border-radius: var(--padding);
    background-color: var(--bg-color);
    box-shadow: 0 0px 0.6px rgba(0, 0, 0, 0.024),
      0 0px 1.3px rgba(0, 0, 0, 0.031), 0 0px 2.2px rgba(0, 0, 0, 0.035),
      0 0px 3.3px rgba(0, 0, 0, 0.037), 0 0px 4.8px rgba(0, 0, 0, 0.04),
      0 0px 6.7px rgba(0, 0, 0, 0.041), 0 0px 9.6px rgba(0, 0, 0, 0.043),
      0 0px 13.9px rgba(0, 0, 0, 0.046), 0 0px 21.4px rgba(0, 0, 0, 0.05),
      0 0px 38px rgba(0, 0, 0, 0.06);
  }
}

