.nowplaying {
  bottom: 0;
  z-index: 3;
  display: flex;
  margin: 0px auto;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  transform: translateY(150%);
  transition: transform 0.5s var(--transition);
  --padding-here: calc(var(--padding) * 0.8);
}

.nowplaying.nowplaying--open {
  transform: translateY(0%);
  transition: transform 0.5s ease;
}

.nowplaying__container {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 650px;
  align-items: center;
  padding: var(--padding-here) 0px;
  justify-content: space-between;
  background: var(--bg-color-accent);
  padding-top: calc(var(--padding-here) - 2px);
  border-radius: var(--padding) var(--padding) 0 0;
  border-top: 1px solid var(--color-dim);
  border-left: 1px solid var(--color-dim);
  border-right: 1px solid var(--color-dim);
}

.nowplaying__title__wrapper {
  z-index: 1;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  width: calc(100% - 106px);
  padding: 0px var(--padding-here);
}

.nowplaying__title {
  overflow: hidden;
  font-size: 20px;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding-left: var(--padding-here);
}

.nowplaying__title__icon {
  width: 28px;
}

.nowplaying__button {
  padding-right: var(--padding-here);
}

.nowplaying__progress,
.nowplaying__progress__placeholder {
  bottom: 0;
  height: 4px;
  width: inherit;
  position: absolute;
  background: #fcb045; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #fcb045,
    #fd1d1d,
    #833ab4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #fcb045, #fd1d1d, #833ab4);
}

.nowplaying__progress {
  z-index: 4;
  right: 0;
  max-width: 650px;
  background: var(--bg-color);
}
.nowplaying__progress__placeholder {
  z-index: 3;
}

.nowplaying__icon {
  min-width: 32px;
}

.nowplaying__visualizer {
  position: absolute;
  z-index: 0;
  top: 10px;
}
