.empty {
  display: flex;
  padding: 0 12% 0 12%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - var(--padding) * 7);
  padding-bottom: calc(var(--padding) * 2);
}

.empty__img {
  margin-bottom: var(--padding);
}

.empty__message,
.empty__description {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.empty__message {
  font-weight: 600;
  margin-bottom: calc(var(--padding) / 2);
}

.empty__description {
  font-size: 13px;
}
