.menu {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  will-change: transform;
  transform: translate3d(-120%, 0, 0);
  background: var(--bg-color-secondary);
  transition: transform 0.6s var(--transition);
}

.menu.menu--show {
  transform: translate3d(0%, 0, 0);
}

.menu__content {
  overflow-y: auto;
  height: calc(100% - 88px - var(--padding));
}

.menu__item {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  align-items: center;
  padding: var(--padding);
  padding-right: calc(var(--padding) * 1.5);
}
.menu__item:hover {
  background-color: var(--bg-color-accent);
}

.menu__item__icon {
  height: 32px;
}

.menu__item__name {
  font-size: 18px;
  overflow: hidden;
  font-weight: 400;
  user-select: none;
  white-space: nowrap;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 0px var(--padding) 0px 0px;
  width: calc(100% - 32px - var(--padding) * 2);
}

.menu__item--spacer {
  flex-grow: 1;
}
